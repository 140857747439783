<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <StReloadAndLoaderWrapper
    v-tooltip="cannotEditPermissionTooltip"
    :request-status="requestStatus"
    @reload="loadPermissions"
  >
    <template v-slot:wrappedComponent>
      <v-data-table
        class="permissions-table"
        :items="permissionList"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.text }}
            </td>
            <td>
              <v-switch
                v-model="props.item.isActive"
                class="permissions-table__switch ma-0"
                color="primary"
                :readonly="!canEditPermission"
                @change="$emit('permissions-changed', permissionList)"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </StReloadAndLoaderWrapper>
</template>

<script>
import StReloadAndLoaderWrapper from '@stilingue/st-reload-and-loader-wrapper';
import { mapActions, mapState } from 'vuex';
import requestStatus from '@/mixins/request_status.mixin';
import { cloneDeep } from '@/plugins/lodash';

export default {
  name: 'PermissionsTable',
  components: {
    StReloadAndLoaderWrapper
  },
  mixins: [requestStatus],
  props: {
    role: {
      type: Object,
      default: () => {}
    },
    activePermissions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      permissionList: []
    };
  },
  computed: {
    ...mapState('permissions', [
      'requestStatus',
      'permissions'
    ]),
    canEditPermission() {
      return !(this.role && this.role.internal);
    },
    cannotEditPermissionTooltip() {
      return !this.canEditPermission ? this.$t('Permissions.cannotEditPermission') : '';
    },
    isMaster() {
      return this.activePermissions && this.activePermissions[0] === 'master';
    }
  },
  watch: {
    activePermissions: 'refreshPermissions'
  },
  created() {
    this.loadPermissions();
  },
  methods: {
    ...mapActions('permissions', [
      'getPermissions'
    ]),
    refreshPermissions() {
      this.permissionList.forEach(permission => {
        permission.isActive = this.activePermissions.includes(permission.id) || this.isMaster;
      });
    },
    loadPermissions() {
      return this.getPermissions()
        .then(() => {
          this.permissionList = cloneDeep(this.permissions);
          this.refreshPermissions();
        });
    }
  }
};
</script>

<style lang="scss">
  .permissions-table {
    width: 100%;

    table {
      background-color: #f8f8f8 !important;
    }

    tr td {
      height: 35px !important;
    }

    tr td:last-child {
      width: 50px;
    }
  }

  .permissions-table__switch .v-input__control {
    height: 24px;
  }

  .permissions-table__error-message {
    margin: auto;
  }
</style>
