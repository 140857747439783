export function requiredRule(text) {
  return v => Boolean(v) || text;
}

export function notEmpty(text) {
  return v => Boolean(v && v.trim()) || text;
}

export function minLength(size, text) {
  return v => (v && v.length >= size) || text;
}

export function maxLength(size, text) {
  return v => (v && v.length <= size) || text;
}

export function email(text) {
  return v => /.+@.+\.+.+/u.test(v) || text;
}
