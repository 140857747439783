var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "role-permissions pa-0 mt-12 mb-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "h3",
              [
                _c("StIcon", { attrs: { "icon-name": "hexagon-multiple" } }),
                _c("span", { staticClass: "role-permissions__title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("Role.createEditRolePage.roleName")) +
                      "*\n        "
                  )
                ])
              ],
              1
            ),
            _c("div", { staticClass: "role-permissions__title-info" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("Role.createEditRolePage.roleNameDescription")
                  ) +
                  "\n      "
              )
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("Role.createEditRolePage.roleName"),
                  value: _vm.nameData,
                  rules: _vm.rules,
                  disabled: !_vm.canEditRoleName
                },
                on: { input: _vm.onNameChanged }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }