var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "role-permissions pa-0 mt-6 fluid" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "h3",
              [
                _c("StIcon", {
                  staticClass: "mr-3",
                  attrs: { "icon-name": "hexagon" }
                }),
                _c("span", { staticClass: "subtitle-1 font-weight-bold" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.customTitle ||
                          _vm.$t("Role.createEditRolePage.thisRolePermissions")
                      ) +
                      "\n        "
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0 mr-0", attrs: { cols: "5" } },
            [_c("ProductTabSelection")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 ml-0", attrs: { cols: "7" } },
            [
              _c("PermissionsTable", {
                attrs: {
                  "active-permissions": _vm.activePermissions,
                  role: _vm.role
                },
                on: {
                  "permissions-changed": function($event) {
                    return _vm.$emit("permissions-changed", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }