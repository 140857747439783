<template>
  <v-container
    class="role-permissions pa-0 mt-12 mb-6"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <h3>
          <StIcon icon-name="hexagon-multiple" />
          <span class="role-permissions__title">
            {{ $t('Role.createEditRolePage.roleName') }}*
          </span>
        </h3>
        <div class="role-permissions__title-info">
          {{ $t('Role.createEditRolePage.roleNameDescription') }}
        </div>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :label="$t('Role.createEditRolePage.roleName')"
          :value="nameData"
          :rules="rules"
          :disabled="!canEditRoleName"
          @input="onNameChanged"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StIcon from '@stilingue/st-icon/StIcon';

export default {
  name: 'RoleNameEdit',
  components: {
    StIcon
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    role: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      nameData: this.name
    };
  },
  computed: {
    /**
     * Verify if user can edit the role's name.
     * @returns {boolean}: True if is not internal, False otherwise
     */
    canEditRoleName() {
      return !(this.role && this.role.internal);
    }
  },
  watch: {
    name: 'onNameChanged'
  },
  methods: {
    onNameChanged(value) {
      this.nameData = value;
      this.$emit('role-name-changed', this.nameData);
    }
  }
};
</script>

<style scoped lang="scss">
  h3 {
    ::v-deep {
      font-weight: bolder !important;
    }
  }

  button.btn--disabled {
    background-color: rgba(100, 100, 100, 0.5) !important;
  }

  .role-permissions {
    &__title-info {
      font-size: 12px;
      color: var(--gray-200);
      padding-left: 30px;
      padding-right: 60px;
      margin-top: 8px;
    }

    &__title {
      font-weight: bold;
    }
  }
</style>
