<template>
  <v-container
    class="role-permissions pa-0 mt-6 fluid"
  >
    <v-row>
      <v-col cols="12">
        <h3>
          <StIcon
            icon-name="hexagon"
            class="mr-3"
          />
          <span class="subtitle-1 font-weight-bold">
            {{ customTitle || $t('Role.createEditRolePage.thisRolePermissions') }}
          </span>
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="5"
        class="pr-0 mr-0"
      >
        <ProductTabSelection />
      </v-col>

      <v-col
        cols="7"
        class="pl-0 ml-0"
      >
        <PermissionsTable
          :active-permissions="activePermissions"
          :role="role"
          @permissions-changed="$emit('permissions-changed', $event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PermissionsTable from './PermissionsTable';
import ProductTabSelection from './ProductTabSelection';
import StIcon from '@stilingue/st-icon/StIcon';

export default {
  name: 'RolePermissions',
  components: {
    StIcon,
    PermissionsTable,
    ProductTabSelection
  },
  props: {
    customTitle: {
      type: String,
      default: '',
    },
    activePermissions: {
      type: Array,
      default() {
        return [];
      }
    },
    role: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped lang="scss">
  .role-permissions {
    &__title {
      display: inline;
      vertical-align: top;
      font-weight: bold;
    }
  }
</style>
