var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("StReloadAndLoaderWrapper", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.cannotEditPermissionTooltip,
        expression: "cannotEditPermissionTooltip"
      }
    ],
    attrs: { "request-status": _vm.requestStatus },
    on: { reload: _vm.loadPermissions },
    scopedSlots: _vm._u([
      {
        key: "wrappedComponent",
        fn: function() {
          return [
            _c("v-data-table", {
              staticClass: "permissions-table",
              attrs: {
                items: _vm.permissionList,
                "hide-default-header": "",
                "hide-default-footer": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(props) {
                    return [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(props.item.text) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c("v-switch", {
                              staticClass: "permissions-table__switch ma-0",
                              attrs: {
                                color: "primary",
                                readonly: !_vm.canEditPermission
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$emit(
                                    "permissions-changed",
                                    _vm.permissionList
                                  )
                                }
                              },
                              model: {
                                value: props.item.isActive,
                                callback: function($$v) {
                                  _vm.$set(props.item, "isActive", $$v)
                                },
                                expression: "props.item.isActive"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }